import { defineMessages } from 'react-intl'

export default defineMessages({
  nameLabel: {
    id: 'contact.form.nameSurname.label',
    defaultMessage: 'Ime i prezime',
  },
  namePlaceholder: {
    id: 'contact.form.nameSurname.placeholder',
    defaultMessage: 'Pero Perić',
  },
  emailLabel: {
    id: 'contact.form.email.label',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'contact.form.email.placeholder',
    defaultMessage: 'primjer@domena.hr',
  },
  phoneLabel: {
    id: 'contact.form.phone.label',
    defaultMessage: 'Telefon',
  },
  messageLabel: {
    id: 'contact.form.message.label',
    defaultMessage: 'Poruka',
  },
  messagePlaceholder: {
    id: 'contact.form.message.placeholder',
    defaultMessage: 'Unesite Vaš upit',
  },

  submit: {
    id: 'contact.form.submit',
    defaultMessage: 'pošalji',
  },
  privacyPreText: {
    id: 'contact.form.gdpr.preText',
    defaultMessage:
      'Podaci uneseni u ovu formu bit ce korišteni isključivo za kontaktiranje Vas u skladu sa našim ',
  },
  privacyLinkText: {
    id: 'contact.form.gdpr.linkText',
    defaultMessage: 'Pravilima privatnosti',
  },
  privacyAfterText: {
    id: 'contact.form.gdpr.afterText',
    defaultMessage: '.',
  },

  phoneError: {
    id: 'contact.form.errors.phone.valid',
    defaultMessage: 'Molimo unesite ispravan broj!',
  },
})
