import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { routes } from 'assets/constants'
import SiteNavigation from '../SiteNavigation'
import SiteFooter from '../Footer'

interface Props {
  topChildren: React.ReactNode
}
const CentralContent: React.FC<Props> = ({ children, topChildren }) => {
  const match = useRouteMatch({
    path: [
      routes.about,
      routes.projects + routes.gallery,
      routes.projects + routes.map,
      routes.editor,
      routes.contact,
      routes.contactOurTeam,
      routes.contactComposeMessage,
    ],
    exact: true,
  })
  const matchContact = useRouteMatch({
    path: [
      routes.contact,
      routes.contactOurTeam,
      routes.contactComposeMessage,
    ],
    exact: true,
  })
  return (
    <div
      className="central-content container-fluid"
      style={{ overflow: 'hidden' }}
    >
      <div className="row flex-nav-bp-nowrap central-navigation">
        <SiteNavigation wrapperClasses="col-12 col-nav-bp d-flex border-bottom-md border-right-md border-right-nav-bp-0 bg-diagonale" />
        <div
          className={`col-nav-bp-7 d-flex align-items-center px-0 ${
            match ? 'bg-diagonale border-bottom' : ''
          }`}
        >
          {topChildren}
        </div>
      </div>
      <div className="row body-content flex-grow-1">
        <div className="col-12 d-flex justify-content-center px-0">
          {children}
        </div>
      </div>

      {match ? <SiteFooter /> : ''}
    </div>
  )
}

export default CentralContent
