import { useReducer, useState, useEffect } from 'react'
import api from 'api/paths'
import reducer, { ReducerState, ReducerAction } from 'api/load-reducer'
import axios, { CancelTokenSource } from 'axios'

// type ContactForm = {
//   name: string
//   surname: string
//   email: string
//   message: string
//   phonenumber: string | undefined
//   file: string | undefined
//   type: 'editor' | 'contact'
// }

const initialState: ReducerState<null> = {
  isLoading: false,
  data: null,
}
type ContactFormReducer = (
  state: ReducerState<null>,
  action: ReducerAction<null>
) => ReducerState<null>

const useContactForm = () => {
  // query state
  const [state, dispatch] = useReducer(
    reducer as ContactFormReducer,
    initialState
  )

  // eslint-disable-next-line consistent-return
  const post = async (body: any, onSuccess: () => void) => {
    dispatch({ type: 'LOAD' })

    const url = api.baseUrl + api.paths.contactForm
    try {
      const formData = new FormData()
      Object.keys(body).forEach(key =>
        formData.append(key, body[key] as string)
      )
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error(response.status.toString())
      dispatch({ type: 'SUCCESS', payload: null })
      onSuccess()
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle if request was cancelled
      } else {
        // Handle usual errors
        dispatch({ type: 'ERROR' })
      }
    }
  }

  return { ...state, post } as const
}

export default useContactForm
