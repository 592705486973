import React, { Suspense } from 'react'
import './app.scss'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as views from 'views'
import routes from 'assets/constants/routes'
import DataContainer from 'components/data-container'
import SiteNavigationAddOn from 'components/navigation-modules/SiteNavigationAddOn'
import SubmitImgBtn from 'components/submit-img-btn'
import SplashScreen from 'components/splash-screen'
import { Scrollbars } from 'react-custom-scrollbars'
import MainNavigation from 'components/navigation/MainNavigation'

const Gdpr = React.lazy(() => import('packages/gdpr/components/gdpr'))

function App() {
  return (
    <Suspense fallback={<SplashScreen />}>
      <Switch>
        <Route exact path={routes.gdpr}>
          <Gdpr />
        </Route>

        <Scrollbars style={{ minHeight: '100vh' }} autoHide autoHeightMin={0}>
          <div className="App">
            <DataContainer>
              <MainNavigation
                topChildren={
                  <Switch>
                    <Route path={routes.projects}>
                      <SiteNavigationAddOn />
                    </Route>
                    <Route path={routes.editor}>
                      <div className="row mx-0 w-100 justify-content-end align-items-center pr-3">
                        {/* <button className="btn btn-outline-primary-light mr-3">Odustani</button> */}
                        <SubmitImgBtn />
                      </div>
                    </Route>
                    <Route exact path={routes.home}>
                      <div className="w-100 h-100" style={{ cursor: 'none' }} />
                    </Route>
                  </Switch>
                }
              >
                <Suspense fallback={<SplashScreen />}>
                  <Switch>
                    <Route path={routes.about}>
                      <views.About />
                    </Route>
                    <Route path={routes.contact}>
                      <views.Contact />
                    </Route>
                    <Route exact path={routes.projects}>
                      <Redirect to={`${routes.projects}${routes.gallery}`} />
                    </Route>
                    <Route path={routes.projects}>
                      <views.Projects />
                    </Route>
                    <Route path={routes.editor}>
                      <views.Editor />
                    </Route>
                    <Route path={routes.home}>
                      <views.Home />
                    </Route>
                    {/* error page? */}
                  </Switch>
                </Suspense>
              </MainNavigation>
            </DataContainer>
          </div>
        </Scrollbars>
      </Switch>
    </Suspense>
  )
}

export default App
