import React from 'react'
import { Associate } from 'api/use-associates'
import './associate-gallery.scss'

interface Props {
  associates: Associate[]
}

const AssociateGallery: React.FC<Props> = ({ associates }) => {
  return (
    <div className="col-12 associate-gallery">
      <div className="row">
        {associates.map((associate: Associate) => (
          <a
            key={associate.name}
            href={associate.link || ''}
            target="_blank"
            rel="noopener noreferrer"
            title={associate.name}
            className="col-6 col-sm-4 col-md-6 col-xl-3 d-flex flex-column associate-item"
          >
            <img
              src={associate.image}
              alt={associate.name}
              title={associate.name}
              className="associate-img"
            />
            <div className="associate-title">{associate.name}</div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default AssociateGallery
