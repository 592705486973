export type ReducerState<T> = {
  isLoading: boolean
  isError?: boolean
  data: T
}
export type ReducerAction<T> =
  | {
      type: 'SUCCESS'
      payload: T
    }
  | { type: 'LOAD' }
  | { type: 'ERROR' }

const reducer = <T>(
  state: ReducerState<T>,
  action: ReducerAction<T>
): ReducerState<T> => {
  switch (action.type) {
    case 'LOAD':
      return { ...state, isLoading: true, isError: false }

    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      }

    case 'ERROR':
      return { ...state, isLoading: false, isError: true }

    default:
      return state
  }
}

export default reducer
