import { defineMessages } from 'react-intl'

export default defineMessages({
  before: {
    id: 'project.before',
    defaultMessage: 'Prije',
  },
  after: {
    id: 'project.after',
    defaultMessage: 'Poslije',
  },
})
