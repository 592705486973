import { defineMessages } from 'react-intl'

export default defineMessages({
  location: {
    id: 'portfolio.map.road.location',
    defaultMessage: 'Lokacija',
  },
  investor: {
    id: 'portfolio.map.road.investor',
    defaultMessage: 'Investitor',
  },
  description: {
    id: 'portfolio.map.road.description',
    defaultMessage: 'Opis',
  },
  euFund: {
    id: 'portfolio.map.road.euFund',
    defaultMessage: 'EU Fond',
  },
})
