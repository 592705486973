import { defineMessages } from 'react-intl'

export default defineMessages({
  about: {
    id: 'navigation.about',
    defaultMessage: 'O nama',
  },
  contact: {
    id: 'navigation.contact',
    defaultMessage: 'Kontakt',
  },
  portfolio: {
    id: 'navigation.portfolio',
    defaultMessage: 'Portfolio',
  },
  directions: {
    id: 'navigation.directions',
    defaultMessage: 'Do nas',
  },
  editor: {
    id: 'navigation.editor',
    defaultMessage: 'Uređivač',
  },
})
