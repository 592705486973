import React, { useContext } from 'react'

import { useIntl } from 'react-intl'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faEnvelope,
//   faPhoneAlt,
//   faTimes,
// } from '@fortawesome/pro-light-svg-icons'
import './style.scss'
import { Switch, Route, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { routes, bureauLocation } from 'assets/constants'
import Team from 'components/team'
import avatar from 'assets/images/avatar.png'
import inquirySuccess from 'assets/images/inquiry-success.png'
import GoogleMapWithMarkers from 'components/google-map'
import { DataContext } from 'components/data-container/data-container'
import ContactForm from './components/contact-form'
import messages from './contact.messages'
import { FaEnvelope, FaPhoneAlt, FaTimes } from 'assets'

const Contact: React.FC = () => {
  const { formatMessage: f } = useIntl()
  const { teamMembers } = useContext(DataContext)
  const match = useRouteMatch()
  return (
    <div className="row flex-grow-1">
      <div className="col-12 contact-section d-flex flex-column">
        <div className="row flex-grow-1 mx-0">
          <div className="col-12 col-md-6 col-lg border-right-md about bg-diagonale px-4 py-5">
            <div className="email-main mb-4">
              <a
                href={`mailto:${f(messages.email)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="gray-light"
              >
                {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                <FaEnvelope />
                <span className="ml-3">{f(messages.email)}</span>
              </a>
            </div>
            <div className="phone-main">
              <a href={`tel:${f(messages.phone)}`}>
                {/* <FontAwesomeIcon icon={faPhoneAlt} /> */}
                <FaPhoneAlt />
                <span className="ml-3">{f(messages.phone)}</span>
              </a>
            </div>

            <div className="row contact-separator mb-6 mx-0" />

            <div className="contact-details">
              <div className="small text-primary-light mb-2">
                {f(messages.inquiryRecipients)}
              </div>
              <Link
                to={`${routes.contact}/our-team`}
                className="contacts-avatars mb-4 d-inline-block"
              >
                {/* {coreMembers.length} */}
                {teamMembers.data
                  .filter(member => member.isVip)
                  .map(member => (
                    <div key={member.phoneNumber} className="item">
                      <img src={member.image || avatar} alt={member.name} />
                    </div>
                  ))}
                {/* <div className="ml-3 d-inline">{`+${djelatnici.length}`}</div> */}
              </Link>

              <div className="group mb-4">
                <div className="small text-primary-light">
                  {f(messages.officeTitle)}
                </div>
                <div className="group-item">
                  {`${f(messages.hqLabel)}: `}
                  <b>{f(messages.hqAddress)}</b>
                </div>
                {/* <div className="group-item">
                  {`${f(messages.bureauLabel)}: `}
                  <b>{f(messages.bureauAddress)}</b>
                </div> */}
              </div>

              <div className="group mb-4">
                <div className="small text-primary-light">
                  {f(messages.firmInfo)}
                </div>
                <div className="group-item">
                  {`${f(messages.oibLabel)}: `}
                  <b>{`${f(messages.oibValue)}`}</b>
                </div>
                <div className="group-item">
                  {`${f(messages.mbTitle)}: `}
                  <b>{`${f(messages.mbValue)}`}</b>
                </div>
                <div className="group-item">
                  {`${f(messages.pdvIdBrTitle)}: `}
                  <b>{`${f(messages.pdvIdBrValue)}`}</b>
                </div>
              </div>

              <div className="group">
                <div className="group-item">
                  {`${f(messages.bankTitle)} `}
                  <b>{`${f(messages.bankValue)}`}</b>
                </div>
                <div className="group-item">
                  {`${f(messages.ibanTitle)}: `}
                  <b>{`${f(messages.ibanValue)}`}</b>
                </div>
                <div className="group-item">
                  {`${f(messages.swiftBicTitle)}: `}
                  <b>{`${f(messages.swiftBicValue)}`}</b>
                </div>
              </div>
            </div>
          </div>
          <Switch>
            <Route exact path={match.path}>
              <div className="col-12 col-md-6 px-0 col-lg-7 bg-diagonale px-0 d-flex flex-column align-items-center regular justify-content-between">
                <div className="modal-heading-md mt-6 mb-2">
                  {f(messages.contactQuestion)}
                </div>
                <Link
                  to={`${match.path}/compose-message`}
                  className="btn btn-darker mt-3 mb-5"
                >
                  {f(messages.openForm)}
                </Link>
                <div
                  className="w-100 flex-grow-1 border-top"
                  style={{ minHeight: 400 }}
                >
                  <GoogleMapWithMarkers
                    buildings={[
                      {
                        location: bureauLocation,
                        title: f(messages.markerMessage),
                        permalink: '',
                      },
                    ]}
                  />
                </div>
              </div>
            </Route>

            <Route path={`${match.path}/our-team`}>
              <div className="col-12 col-md-6 px-0 col-lg-7 bg-white indented-up d-flex flex-column align-items-center">
                <div className="modal-heading-md mt-6 mb-8">
                  {f(messages.inquiryRecipients)}
                </div>
                <div className="row w-100">
                  <Team
                    teamMembers={teamMembers.data.filter(
                      member => member.isVip
                    )}
                    memberClassNames="col-6  col-lg-6 mb-1 mb-lg-6"
                  />
                </div>

                <Link to={routes.contact} className="close-contact-modal">
                  {/* <FontAwesomeIcon icon={faTimes} /> */}
                  <FaTimes size={14} color="rgba(0,0,0,.54)" />
                </Link>
              </div>
            </Route>

            <Route path={`${match.path}/compose-message`}>
              <div className="col-12 col-md-6 col-lg-7 bg-white indented-up d-flex flex-column align-items-center pt-6 pb-4">
                <div className="modal-heading-lg mb-4">
                  {f(messages.formTitle)}
                </div>
                <ContactForm />

                <Link to={routes.contact} className="close-contact-modal">
                  {/* <FontAwesomeIcon icon={faTimes} /> */}
                  <FaTimes size={14} color="rgba(0,0,0,.54)" />
                </Link>
              </div>
            </Route>
            <Route path={`${match.path}/finished`}>
              <div className="col-12 col-md-6 px-0 col-lg-7 bg-white indented-up d-flex flex-column align-items-center justify-content-center pt-6 pb-4">
                <img src={inquirySuccess} alt="" />
                <div className="modal-heading-md mb-3">
                  {f(messages.successConfirmation)}
                </div>
                <p className="pb-8 px-3 text-center">
                  {f(messages.successAffirmation)}
                </p>
                <Link to={routes.contact} className="close-contact-modal">
                  {/* <FontAwesomeIcon icon={faTimes} /> */}
                  <FaTimes size={14} color="rgba(0,0,0,.54)" />
                </Link>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default Contact
