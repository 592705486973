import { api } from 'assets/constants'

export type Messages = Record<string, string>

export const fetchMessages = async (locale: string): Promise<Messages> => {
  const response = await fetch(
    `${api.baseUrl}${api.paths.translation}?lang_ID=${locale}.json`
  )
  try {
    if (!response.ok) throw new Error(response.status.toString())
    return await response.json()
  } catch (e) {
    console.error(e)
  }
  return {}
}

export const fetchSupportedLanguages = async (): Promise<string[]> => {
  const response = await fetch(`${api.baseUrl}${api.paths.supportedLanguages}`)
  try {
    if (!response.ok) throw new Error(response.status.toString())
    return await response.json()
  } catch (e) {
    console.error(e)
  }
  return []
}
