import { defineMessages } from 'react-intl'

export default defineMessages({
  submitImage: {
    id: 'editor.send',
    defaultMessage: 'Pošalji upit',
  },
  callToAction: {
    id: 'editor.callToAction',
    defaultMessage: 'Skicirajte nam svoje ideje i pošaljite upit',
  },
})
