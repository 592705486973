import React, { useState } from 'react'
import { Marker, InfoWindow } from 'react-google-maps'
import defaultMarker from 'assets/images/map-pin2.png'

const MarkerWithInfoWindow = ({
  position,
  children,
  markerIcon = defaultMarker,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  return (
    <Marker
      position={position}
      onClick={() => setShowInfoWindow(prev => !prev)}
      icon={markerIcon}
    >
      {showInfoWindow && (
        <InfoWindow onCloseClick={() => setShowInfoWindow(false)}>
          {children}
        </InfoWindow>
      )}
    </Marker>
  )
}

export default MarkerWithInfoWindow
