import React, { useState, useContext } from 'react'
import './form-style.scss'
import { routes } from 'assets/constants'
import { Link, useHistory } from 'react-router-dom'
import { DataContext } from 'components/data-container/data-container'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import { useIntl } from 'react-intl'
import AttachmentNotification from '../attachment-notification'
// import messages from '../../contact.messages'
import messages from './contact-form.messages'

const messageMaxLength = 200
const defaultDialCode = '385'

const ContactForm = () => {
  const { formatMessage: f } = useIntl()
  const { contactData } = useContext(DataContext)
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  })
  const [dialCode, setDialCode] = useState(defaultDialCode)
  const { contactForm } = useContext(DataContext)

  const history = useHistory()
  const handleSubmit = event => {
    const nandsn = contactData.name.value.trim().split(/\s+/)
    contactForm.post(
      {
        name: nandsn[0],
        surname: nandsn.slice(1).join(' '),
        email: contactData.email.value,
        message: contactData.message.value,
        phonenumber: contactData.phone.value.length
          ? `+${dialCode} ${contactData.phone.value.replace(/\s+/g, '')}`
          : undefined,
        file: contactData.image.value.length
          ? contactData.image.value
          : undefined,
        type: contactData.image.value.length ? 'editor' : 'contact',
      },
      () => history.push(`${routes.contact}/finished`)
    )
  }

  return (
    <div className="contact-form">
      <div className="form-wrapper">
        <form noValidate>
          <div className="fullName">
            <label htmlFor="fullName">{f(messages.nameLabel)}</label>
            <input
              type="text"
              name="fullName"
              placeholder={f(messages.namePlaceholder)}
              noValidate
              value={contactData.name.value}
              onChange={e => contactData.name.set(e.target.value)}
              onBlur={() => setTouched(prev => ({ ...prev, name: true }))}
              className={
                touched.name && contactData.name.errors.length ? 'error' : ''
              }
              disabled={contactForm.isLoading}
            />
            {touched.name &&
              contactData.name.errors.length > 0 &&
              contactData.name.errors.map(message => (
                <span className="error-message" key={`name-error-${message}`}>
                  {message}
                </span>
              ))}
          </div>

          <div className="email">
            <label htmlFor="email">{`${f(messages.emailLabel)}*`}</label>
            <input
              type="email"
              name="email"
              placeholder={f(messages.emailPlaceholder)}
              noValidate
              value={contactData.email.value}
              onChange={e => contactData.email.set(e.target.value)}
              onBlur={() => setTouched(prev => ({ ...prev, email: true }))}
              className={
                touched.email && contactData.email.errors.length ? 'error' : ''
              }
              disabled={contactForm.isLoading}
            />
            {touched.email &&
              contactData.email.errors.length > 0 &&
              contactData.email.errors.map(message => (
                <span className="error-message" key={`email-error-${message}`}>
                  {message}
                </span>
              ))}
          </div>

          <div className="phone">
            <label htmlFor="phone">{f(messages.phoneLabel)}</label>
            <IntlTelInput
              defaultCountry="hr"
              fieldName="phone"
              inputClassName={`form-control ${
                touched.phone && contactData.phone.errors.length ? 'error' : ''
              }`}
              value={contactData.phone.value}
              onPhoneNumberChange={(
                isValid,
                num,
                localeData,
                numWithDialCode
              ) => {
                contactData.phone.set(num)
                contactData.setErrors(prev => ({
                  ...prev,
                  phone: num.length && !isValid ? [f(messages.phoneError)] : [], // performs validation here rather than within the useContactData hook
                }))
              }}
              onPhoneNumberBlur={() =>
                setTouched(prev => ({ ...prev, phone: true }))
              }
              onSelectFlag={(num, localeData, smth, isValid) => {
                setDialCode(localeData.dialCode)
                contactData.setErrors(prev => ({
                  ...prev,
                  phone: num.length && !isValid ? [f(messages.phoneError)] : [], // performs validation here rather than within the useContactData hook
                }))
              }}
              disabled={contactForm.isLoading}
            />
            {touched.phone &&
              contactData.phone.errors.length > 0 &&
              contactData.phone.errors.map(message => (
                <span className="error-message" key={`phone-error-${message}`}>
                  {message}
                </span>
              ))}
          </div>

          <div className="message ">
            <label htmlFor="message">{`${f(messages.messageLabel)}*`}</label>
            <textarea
              rows="6"
              name="message"
              value={contactData.message.value}
              placeholder={f(messages.messagePlaceholder)}
              onChange={e => contactData.message.set(e.target.value)}
              noValidate
              maxLength={messageMaxLength}
              onBlur={() => setTouched(prev => ({ ...prev, message: true }))}
              className={
                touched.message && contactData.message.errors.length
                  ? 'error'
                  : ''
              }
              disabled={contactForm.isLoading}
            />
            <div className="d-flex flex-row w-100 justify-content-between">
              <div className="d-flex flex-column">
                {touched.message &&
                  contactData.message.errors.length > 0 &&
                  contactData.message.errors.map(message => (
                    <span
                      className="error-message"
                      key={`message-error-${message}`}
                    >
                      {message}
                    </span>
                  ))}
              </div>
              <div className="char-counter ">
                {`${contactData.message.value.length}/${messageMaxLength}`}
              </div>
            </div>
            <AttachmentNotification />
          </div>

          <div className="privacy-disclaimer">
            <div className="small">
              {f(messages.privacyPreText)}
              <Link to={routes.gdpr} target="_blank">
                {f(messages.privacyLinkText)}
              </Link>
              {f(messages.privacyAfterText)}
            </div>
          </div>

          <div className="submit mt-3">
            <button
              type="button"
              className={`btn btn-primary-light ${!contactData.isValid &&
                'disabled'} ml-auto btn-wide ${
                contactForm.isLoading ? 'px-3 disabled' : 'px-5'
              }`}
              onClick={handleSubmit}
            >
              <span
                className={`spinner-border spinner-border-sm mr-3 ${!contactForm.isLoading &&
                  'd-none'}`}
              />
              {f(messages.submit)}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
  // }
}

export default ContactForm
