import React, { useContext, useState } from 'react'
import { useIntl, FormattedHTMLMessage } from 'react-intl'
import Team from 'components/team'
import ReferenceGallery from 'views/about/components/reference-gallery'

import AssociateGallery from 'views/about/components/associate-gallery'
import funds from 'assets/constants/funds'
import { DataContext } from 'components/data-container/data-container'
import messages from './about.messages'

const About: React.FC = () => {
  const [showReferences, setShowReferences] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const { teamMembers, associates, partners, references } = useContext(
    DataContext
  )
  const { formatMessage: f } = useIntl()
  return (
    <div className="row w-100">
      <div className="col-12 about-section d-flex flex-column">
        <div className="row">
          <div className="col-md-6 col-lg border-right-md border-bottom about bg-diagonale px-4 py-5">
            <h1>{f(messages.title)}</h1>
            {/* <blockquote>{f(messages.motto)}</blockquote> */}
            <div
              className="about-text-body"
              style={{
                height: showMore ? 'auto' : 500,
                overflow: 'hidden',
              }}
            >
              {/* <ShowMoreText
                lines={27}
                more={f(messages.more)}
                less={f(messages.less)}
                expanded={showMore}
                onClick={() => setShowMore(prev => !prev)}
              > */}
              <FormattedHTMLMessage
                id={messages.about.id}
                defaultMessage={messages.about.defaultMessage}
              />
              <h6 style={{fontWeight: "normal", marginTop: "1rem"}}>DIGITALNA TRANSFORMACIJA 2022.</h6><p style={{margin:0}}>Digitalna transformacija poduzeća Blok A3 d.o.o. - NPOO.C1.1.2.R3-I3.01.0242</p><br/><p style={{margin:0}}>KORISNIK</p><p style={{margin:0}}>Blok A3 d.o.o.</p><p style={{margin:0}}>Prilaz Slave Raškaj 12</p><p style={{margin:0}}>10000 Zagreb</p><p style={{margin:0}}>OIB: 46529632639</p><br/><p style={{margin:0}}>VRIJEDNOST PROJEKTA</p><p style={{margin:0}}>Ukupna vrijednost: 135.128,14 €</p><p style={{margin:0}}>Ukupno prihvatljivi troškovi: 127.828,39 €</p><p style={{margin:0}}>Bespovratna sredstva: 70.305,61 €</p><br/><p style={{margin:0}}>RAZDOBLJE PROVEDBE</p><p style={{margin:0}}>Od 01.09.2022. do 01.09.2024. godine</p>

              {/* </ShowMoreText> */}
            </div>
            <button
              type="button"
              className="btn btn-link text-lowercase text-capitalize font-weight-light"
              onClick={() => setShowMore(prev => !prev)}
            >
              {showMore ? f(messages.less) : f(messages.more)}
            </button>

            <div className="w-100 d-flex flex-wrap">
              {funds.map(fund => (
                <a
                  key={fund.img}
                  href={fund.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 p-0 d-flex align-items-center"
                >
                  <img src={fund.img} alt="fund" className="w-100" />
                </a>
              ))}
              <p
                 
                  className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 p-0 d-flex align-items-center ml-4"
                >
                  <img src={"/images/eu.png"} alt="fund" className="w-100" />
                </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-7 border-bottom members bg-diagonale">
            <h5 className="text-center mb-5 font-weight-bold d-md-none">
              {f(messages.teamTitle)}
            </h5>
            <div className="row">
              <Team
                teamMembers={teamMembers.data.filter(member => member.isVip)}
                memberClassNames="col-6 col-lg-6 mb-1 mb-lg-6"
              />
              <Team
                teamMembers={teamMembers.data.filter(member => !member.isVip)}
                size={0.75}
                memberClassNames="col-6 col-sm-4 col-md-6 col-lg-4 mb-4"
              />
            </div>
          </div>
        </div>

        <div className="row flex-grow-1">
          <div className="col-md-6 col-lg border-right-md border-bottom border-bottom-md-0 references bg-diagonale px-4">
            <h2>
              <span>{f(messages.referencesTitle)}</span>
            </h2>
            <ReferenceGallery
              references={
                showReferences ? references.data : references.data.slice(0, 8)
              }
            />
            {references.data.length > 8 && (
              <a
                href="#"
                className="m-3 font-weight-light"
                onClick={() => setShowReferences(prev => !prev)}
              >
                {showReferences ? f(messages.less) : f(messages.more)}
              </a>
            )}
          </div>

          <div className="col-md-6 col-lg-7 partners bg-diagonale px-4">
            <h2>
              <span>{f(messages.partnersTitle)}</span>
            </h2>

            <div className="mb-8 w-100 ">
              <AssociateGallery associates={associates.data} />
            </div>

            <h2>{f(messages.associatesTitle)}</h2>

            <div className="w-100 mb-7">
              <div className="row">
                {partners.data.map(partner => (
                  <a
                    key={partner.image}
                    href={partner.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="col-6 col-sm-4 col-md-6 col-xl-3 justify-content-center"
                  >
                    <img
                      src={partner.image}
                      alt="partner"
                      className="partner-img  mx-auto"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
