import React from 'react'
import negativeMarker from 'assets/images/map-pin1.png'
import { useIntl } from 'react-intl'
import './road-map-marker.scss'
import ph from 'assets/images/img-3.jpg'
import AspectRatioBox from 'components/aspect-ratio-box'
import { MapObject } from 'api/types'
import placeholder from 'api/placeholder'
import messages from './road-map-marker.messages'
import MarkerWithInfoWindow from '../marker-with-info-window'

export interface Road extends MapObject {
  locationName: string
  investor: string
  description: string
  euFund: string
}

const PLACEHOLDER_ROAD: Road = {
  locationName: 'Oriovac',
  description:
    'Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.',
  euFund: 'Tip operacija 2341e - "Ulaganje u siumsku infrastrukturu"',
  investor: 'Opcina Oriovac',
  location: { lat: 45.1715803, lng: 17.7505138 },
  permalink: '',
  title: '[PH cesta]',
  imgSrc: ph,
  year: '2020',
}
const windowWidth = 400
interface Props {
  road: Road
}
const RoadMapMarker: React.FC<Props> = ({
  // road: REPLACED_WITH_PLACEHOLDER,
  road,
}) => {
  // const road = PLACEHOLDER_ROAD

  const { formatMessage: f } = useIntl()
  if (!road.location.lat || !road.location.lng) return null
  return (
    <MarkerWithInfoWindow
      key={road.permalink}
      position={road.location}
      markerIcon={negativeMarker}
    >
      <div className="container w-100 h-100 px-0">
        {road.imgSrc && (
          <AspectRatioBox>
            <img
              src={`${road.imgSrc}?width=${windowWidth}&height=${(windowWidth *
                9) /
                16}&mode=crop`}
              // src={ph}
              alt={road.title}
              title={road.title}
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          </AspectRatioBox>
        )}
        <div className="col py-2 px-2" style={{ width: windowWidth }}>
          <h6
            style={{
              textTransform: 'uppercase',
              marginRight: road.imgSrc ? 0 : 40,
            }}
            className="text-primary-light"
          >
            {road.title}
          </h6>
          <table className="road-info-table">
            <tbody>
              {!!road.locationName && (
                <tr>
                  <th>{f(messages.location)}</th>
                  <td>{road.locationName}</td>
                </tr>
              )}
              {!!road.investor && (
                <tr>
                  <th>{f(messages.investor)}</th>
                  <td>{road.investor}</td>
                </tr>
              )}
              {!!road.description && (
                <tr>
                  <th>{f(messages.description)}</th>
                  <td>{road.description}</td>
                </tr>
              )}
              {!!road.euFund && (
                <tr>
                  <th>{f(messages.euFund)}</th>
                  <td>{road.euFund}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MarkerWithInfoWindow>
  )
}

export default RoadMapMarker
