/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import './reference-gallery.scss'
import Lightbox from 'components/lightbox'
import { Reference } from 'api/use-references'

interface Props {
  references: Reference[]
}
const ReferenceGallery: React.FC<Props> = ({ references }) => {
  return (
    <>
      <div className="col-12 reference-gallery px-0">
        <div className="row">
          {references.map((reference, index) => (
            <Lightbox
              key={reference.image}
              className="col-6 col-sm-4 col-md-6 col-xl-3 d-flex flex-column reference-item"
              images={references.map(ref => ({
                src: ref.image,
                caption: ref.name,
              }))}
              index={index}
            >
              <img
                src={`${reference.image}?width=160&height=90&mode=crop`}
                alt={reference.name}
                className="reference-img"
              />
              <div className="reference-title">{reference.name}</div>
            </Lightbox>
          ))}
        </div>
      </div>
    </>
  )
}

export default ReferenceGallery
