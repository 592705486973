import React from 'react'
import avatar from 'assets/images/avatar.png'
import { TeamMember as TeamMemberType } from 'api/use-team-members'

interface Props {
  member: TeamMemberType
  size?: number
  classNames?: string
}
const IMAGE_WIDTH = 140

const TeamMember: React.FC<Props> = ({ member, size = 1, classNames = '' }) => {
  return (
    <div className={`team-member ${classNames}`}>
      <img
        src={`${member.image || avatar}?width=121&height=121&mode=crop`}
        alt={member.name}
        style={{
          borderRadius: '50%'
        }}
      />
      <h3 className="name">{`${member.name} ${member.surname}`}</h3>
      <a href={`mailto:${member.email}`} className="email d-block">
        {member.email}
      </a>
      {!!member.phoneNumber && (
        <a href={`tel:${member.phoneNumber}`} className="phone d-block">
          {member.phoneNumber}
        </a>
      )}
    </div>
  )
}

export default TeamMember
