import React, { useContext, useState, useRef, useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faFilter,
//   faThLarge,
//   faMapMarked,
// } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { routes } from 'assets/constants'
import { DataContext } from 'components/data-container/data-container'
import _ from 'lodash'
import CopyToClipboard from 'components/copy-to-clipboard'
import messages from 'components/filters-dropdown/filters-dropdown.messages'
import { useIntl } from 'react-intl'
import { FaFilter, FaMapMarked, FaThLarge } from 'assets'

const SiteNavigationAddOnSm: React.FC = () => {
  const { filters, projectsData } = useContext(DataContext)

  const [show, setShow] = useState(false)

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    filters.setSingle(id, e.target.checked)
  }

  // close window on click outside
  const filterWindowToggle = useRef<HTMLDivElement>(null)
  const filterButton = useRef<HTMLButtonElement>(null)
  const handleClickOutside = (e: Event) => {
    if (
      filterWindowToggle &&
      filterWindowToggle.current &&
      filterButton &&
      filterButton.current &&
      (filterWindowToggle.current.contains(e.target as Node | null) ||
        filterButton.current.contains(e.target as Node | null))
    )
      return
    setShow(false)
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false)
    }
  }, [])

  const { formatMessage: f } = useIntl()
  return (
    <div className="row addon-sm">
      <div className="row h-100 align-items-center mx-0 w-100">
        <div className="navigation-addon bg-diagonale col-12 d-flex align-items-center">
          <div className="tabs-navigation">
            <NavLink
              to={`${routes.projects}${routes.gallery}`}
              data-tabs="tab-1"
              className="mr-4"
              activeClassName="active-tab"
            >
              {/* <FontAwesomeIcon icon={faThLarge} /> */}
              <FaThLarge />
            </NavLink>
            <NavLink
              to={`${routes.projects}${routes.map}`}
              data-tabs="tab-2"
              activeClassName="active-tab"
            >
              {/* <FontAwesomeIcon icon={faMapMarked} /> */}
              <FaMapMarked />
            </NavLink>
          </div>

          {/* <SelectProjectFilter className="ml-auto" placeholder="Tip projekta" /> */}

          <button
            type="button"
            className="btn btn-outline-dark ml-auto position-relative"
            onClick={() => setShow(prev => !prev)}
            ref={filterButton}
          >
            {/* <FontAwesomeIcon icon={faFilter} /> */}
            <FaFilter />
          </button>
          <CopyToClipboard />
        </div>

        {show && (
          <div
            ref={filterWindowToggle}
            className="filter-modal on-sm d-flex flex-column col-12 "
            style={{ top: 'none', position: 'unset' }}
          >
            <div className="body pb-2">
              <div className="title d-flex align-items-center justify-content-between">
                <span>{f(messages.filtersTitle)}</span>
                <span
                  className={`spinner-border spinner-border-sm ${!projectsData?.isLoading &&
                    'invisible'}`}
                />
              </div>

              {_.values(filters.data).map(filter => (
                <div
                  key={`filter-option-${filter.id}`}
                  className="custom-control custom-checkbox pl-3 py-2"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`tag-${filter.id}`}
                    checked={filter.isSelected}
                    onChange={e => handleCheckboxChange(e, filter.id)}
                  />
                  <label
                    className="custom-control-label d-flex mr-7"
                    htmlFor={`tag-${filter.id}`}
                  >
                    {filter.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SiteNavigationAddOnSm
