import React, { useContext } from 'react'
import { DataContext } from 'components/data-container/data-container'
import { useIntl } from 'react-intl'
import messages from './submit-img-btn.messages'

const SubmitImgBtn = () => {
  const { handleImgSubmit } = useContext(DataContext)

  const { formatMessage: f } = useIntl()
  return (
    <>
      <p className="mb-0 mr-4 d-none d-xl-block">{f(messages.callToAction)}</p>
      <button
        type="button"
        className="btn btn-primary-light btn-wide px-4 d-none d-xl-block"
        onClick={() => handleImgSubmit()}
      >
        {f(messages.submitImage)}
      </button>
    </>
  )
}

export default SubmitImgBtn
