import React, { useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { routes } from 'assets/constants'
import { APIProjectDetails } from 'api/api-types'
import useFetch from 'api/use-fetch'
import api from 'api/paths'
import HalvedImage from 'components/halved-images/halved-images'
import ProjectDetailsSlider from 'views/projects/components/project-details-slider'
import GoogleMapWithMarkers from 'components/google-map'
import { MapObject } from 'api/types'
import { useIntl } from 'react-intl'
import AspectRatioBox from 'components/aspect-ratio-box'
import messages from './project-details.messages'
import './project-details.scss'
import { FaTimes } from 'assets'

const projToMapObj = (proj: APIProjectDetails): MapObject => {
  return {
    location: {
      lat: proj.coordinates.latitude,
      lng: proj.coordinates.longitude,
    },
    title: proj.name || 'Marker',
    year: proj.shortDescription,
    imgSrc: `${api.baseUrl}/uploads/${proj.photoAfter}`,
    permalink: proj.permalink,
  }
}
const isMapPositionDefined = (project: APIProjectDetails): boolean => {
  if (!project.coordinates) return false
  if (!project.coordinates.latitude) return false
  if (!project.coordinates.longitude) return false
  return true
}

const BackgroundSpinner = () => (
  <div
    className="spinner-border"
    role="status"
    style={{
      position: 'absolute',
      top: 'calc(50% - 16px)',
      left: 'calc(50% - 16px)',
    }}
  >
    <span className="sr-only">Loading...</span>
  </div>
)

function ProjectDetails() {
  const params = useParams<{ projectName: string }>()
  const { data, getData } = useFetch<APIProjectDetails>({
    method: 'get',
    path: `${api.paths.projectDetails}`,
  })

  useEffect(() => {
    getData({ params: { link: params.projectName } })
  }, [params])

  const history = useHistory()
  const handleCloseModal = () => {
    history.push(`${routes.projects}${routes.gallery}`)
  }

  useEffect(() => {
    function handleKeyUp(e: KeyboardEvent) {
      if (e.key === 'Escape') handleCloseModal()
    }
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const { formatMessage: f } = useIntl()

  return (
    <section className="project-modal align-items-start">
      <div
        className="col-12 d-flex align-items-center justify-content-between position-sticky bg-diagonale"
        style={{ zIndex: 200, top: 0 }}
      >
        {/* <Link
          to={`${routes.projects}${routes.gallery}`}
          className="d-inline-block p-2 p-lg-3 text-dark"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="ml-2" />
        </Link> */}
        <span
          className="p-2 p-lg-3 text-dark"
          style={{
            minWidth: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data?.name}
        </span>
        {/* <ul className="d-flex flex-row mb-0 social-btns mr-1">
          <li className="list-unstyled">
            <Link
              to="/facebook"
              className="d-inline-block p-2 p-lg-3 text-dark"
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </Link>
          </li>
          <li className="list-unstyled">
            <Link
              to="/instagram"
              className="d-inline-block p-2 p-lg-3 text-dark"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
          </li>
        </ul> */}
        {/* <FacebookShareButton
          url={window.location.href}
          // url="https://bloka3-front.mediatorium.co/portfolio/details/obiteljska-kuca---velika-gorica"
          quote={data?.name}
          hashtag="#bloka3"
          className="d-inline-block p-2 p-lg-3 text-dark"
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </FacebookShareButton> */}
        <Link
          to={`${routes.projects}${routes.gallery}`}
          className="d-inline-block p-2 p-lg-3 text-dark"
        >
          {/* <FontAwesomeIcon icon={faTimes} /> */}
          <FaTimes size={10} color="#656566" />
        </Link>
      </div>

      <div className="container-fluid project-details">
        <div className="row">
          <div className="col-sm-6 px-0">
            <BackgroundSpinner />
            <ProjectDetailsSlider
              images={data?.technicalPhotos}
              variableWidth={false}
            />
          </div>
          <div className="col-sm-6 px-0">
            <BackgroundSpinner />
            <ProjectDetailsSlider
              images={data?.renderPhotos}
              variableWidth={false}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 px-0" style={{ minHeight: 400 }}>
            {process.env.NODE_ENV === 'production' && (
              <GoogleMapWithMarkers
                buildings={
                  data && isMapPositionDefined(data)
                    ? [projToMapObj(data)]
                    : undefined
                }
                draggable={false}
              />
            )}
          </div>
          <div className="col-md-6 bg-darker text-white project-description">
            <h3>{data?.name}</h3>
            <h6>{data?.shortDescription}</h6>

            <div className="desc-item">
              <div className="label">{f(messages.team)}</div>
              <div className="content">{data?.projectTeam}</div>
            </div>

            <div className="desc-item">
              <div className="label">{f(messages.investor)}</div>
              <div className="content">{data?.investor}</div>
            </div>

            <div className="desc-item">
              <div className="label">{f(messages.location)}</div>
              <div className="content">{data?.location}</div>
            </div>

            <div className="desc-item">
              <div className="label">{f(messages.surface)}</div>
              <div className="content">
                {`${data?.surface} m`}
                <sup>2</sup>
              </div>
            </div>

            <div className="desc-item">
              <div className="label">{f(messages.phase)}</div>
              <div className="content">{data?.phase}</div>
            </div>
            {!!data?.euFonds && (
              <div className="desc-item">
                <div className="label">{f(messages.funds)}</div>
                <div className="content">{data?.euFonds}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {data?.photoBefore && data.photoAfter && (
        <div className="container-fluid px-0 bg-diagonale">
          <div className="row mx-0">
            <div
              className="col-12"
              style={{ paddingTop: '56.25%', position: 'relative' }}
            >
              <div className="cropper-content">
                <HalvedImage
                  beforeImg={`${api.baseUrl}/uploads/${data?.photoBefore}`}
                  afterImg={`${api.baseUrl}/uploads/${data?.photoAfter}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {data?.relatedProjectsList && data?.relatedProjectsList.length > 0 && (
        <div className="container-fluid px-0 bg-diagonale">
          <div className="container related-projects pb-8">
            <h6>{f(messages.relatedProjects)}</h6>
            <div className="row">
              {data?.relatedProjectsList.map(relatedProject => (
                <Link
                  to={`${routes.projects}/details/${relatedProject.permaLink}`}
                  className="col-sm-6 col-md-4 item mb-3"
                  key={`related-project-${relatedProject.permaLink}`}
                >
                  <AspectRatioBox>
                    <img
                      src={relatedProject.photoAfter}
                      title={relatedProject.name}
                      alt={relatedProject.name}
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover', objectPosition: 'center' }}
                    />
                  </AspectRatioBox>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid bg-darker">
        <div className="details-footer-section">
          <p className="text-white">
            {`${f(messages.contactQuestion)} `}
            <Link to={routes.contact} className="" style={{ color: '#76acd7' }}>
              {f(messages.contactAction)}
            </Link>
          </p>
        </div>
      </div>
    </section>
  )
}

export default ProjectDetails
