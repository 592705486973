import { useReducer, useEffect } from 'react'
import api from 'api/paths'
import reducer, { ReducerState, ReducerAction } from 'api/load-reducer'
// import { TeamMember } from 'components/team/components/team-member/teamMember'
export interface Reference {
  image: string
  name: string
}
export const initialState: ReducerState<Reference[]> = {
  isLoading: false,
  data: [],
}
type ReferenceReducer = (
  state: ReducerState<Reference[]>,
  action: ReducerAction<Reference[]>
) => ReducerState<Reference[]>

const useReferences = () => {
  const [state, dispatch] = useReducer(
    reducer as ReferenceReducer,
    initialState
  )

  const getData = async () => {
    dispatch({ type: 'LOAD' })
    const response = await fetch(`${api.baseUrl}${api.paths.references}`)
    try {
      if (!response.ok) throw new Error(response.status.toString())
      const json = await response.json()
      dispatch({ type: 'SUCCESS', payload: json })
    } catch (err) {
      dispatch({ type: 'ERROR' })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return [state, getData] as const
}

export default useReferences
