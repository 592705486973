/* eslint-disable react/prop-types */
// import React, { useContext, useState } from 'react'
import React from 'react'
import './quartered-images.scss'
import useMousePosition from 'utils/use-mouse-position'
// import { DataContext } from 'components/data-container/data-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'
// import useHomeImages from 'api/use-home-images'
import { ReducerState } from 'api/load-reducer'
import { RandomImage } from 'api/types'

const color = ['#1E6194', '#304C61', '#4A7494', '#2D93E0']
const placeholderStyles: React.CSSProperties[] = [
  { backgroundColor: color[0] },
  { backgroundColor: color[1] },
  { backgroundColor: color[2] },
  { backgroundColor: color[3] },
]
const barWidth = 113
interface Props {
  images: ReducerState<RandomImage[]>
}
const QuarteredImages: React.FC<Props> = ({ images }) => {
  const {
    dimensions: { width, height },
    position: { x = width / 2, y = height / 2 },
  } = useMousePosition()
  // const { homeImageData = { data: [] } } = useContext(DataContext)

  return (
    <>
      <div className="architect imgs-wrapper quartered container-fluid px-0 fullscreen back ">
        <div className="fixed-bg-wrappers">
          <div
            className="item-1 resizeable"
            style={{ width: x + barWidth, height: y }}
          >
            <div className="up-left img-item" style={placeholderStyles[0]}>
              <LazyLoadImage
                src={
                  (!images.isLoading && images.data && images?.data[0]?.url) ||
                  ''
                }
                placeholder={
                  <div className="w-100 h-100" style={placeholderStyles[0]} />
                }
                effect="opacity"
                wrapperClassName="h-100 w-100"
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </div>
          </div>
          <div
            className="item-2 resizeable"
            style={{ width: x + barWidth, height: height - y }}
          >
            <div className="down-left img-item" style={placeholderStyles[1]}>
              <LazyLoadImage
                src={
                  (!images.isLoading && images.data && images?.data[1]?.url) ||
                  ''
                }
                placeholder={
                  <div className="w-100 h-100" style={placeholderStyles[1]} />
                }
                effect="opacity"
                wrapperClassName="h-100 w-100"
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </div>
          </div>
          <div
            className="item-3 resizeable"
            style={{ width: width - (x + barWidth), height: y }}
          >
            <div className="up-right img-item" style={placeholderStyles[2]}>
              <LazyLoadImage
                src={
                  (!images.isLoading && images.data && images?.data[2]?.url) ||
                  ''
                }
                placeholder={
                  <div className="w-100 h-100" style={placeholderStyles[2]} />
                }
                effect="opacity"
                wrapperClassName="h-100 w-100"
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </div>
          </div>
          <div
            className="item-4 resizeable"
            style={{ width: width - (x + barWidth), height: height - y }}
          >
            <div className="down-right img-item" style={placeholderStyles[3]}>
              <LazyLoadImage
                src={
                  (!images.isLoading && images.data && images?.data[3]?.url) ||
                  ''
                }
                placeholder={
                  <div className="w-100 h-100" style={placeholderStyles[3]} />
                }
                effect="opacity"
                wrapperClassName="h-100 w-100"
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              {/* <img
                src={
                  (homeImageData &&
                    homeImageData.data &&
                    homeImageData.data[3].url) ||
                  ''
                }
                srcSet={`${(homeImageData &&
                  homeImageData.data &&
                  homeImageData.data[0].url) ||
                  ''} 576w, ${(homeImageData &&
                  homeImageData.data &&
                  homeImageData.data[1].url) ||
                  ''} 768w, ${(homeImageData &&
                  homeImageData.data &&
                  homeImageData.data[2].url) ||
                  ''} 1280w`}
                alt=""
                style={{ width: '100%', height: '100%' }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuarteredImages
