/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, useEffect } from 'react'
import { APIProject } from 'api/api-types'
import useFilteredProjects from 'api/use-filtered-projects'
import { ReducerState } from 'api/load-reducer'
import useFilters, { Filter } from 'api/use-filters'
import _ from 'lodash'
import useAssociates, {
  Associate,
  initialState as associatesInitialState,
} from 'api/use-associates'
import usePartners, {
  Partner,
  initialState as partnersInitialState,
} from 'api/use-partners'
import useTeamMembers, {
  TeamMember,
  initialState as teamMembersInitialState,
} from 'api/use-team-members'
import useReferences, {
  Reference,
  initialState as referencesInitialState,
} from 'api/use-references'
import useContactForm from 'api/use-contact-form'
import useContactData from './hooks/use-contact-data'

type FetchData<T> = {
  data: T | undefined
  getData: ({
    params,
    body,
  }: {
    params?:
      | {
          [key: string]: any
        }
      | undefined
    body?: any
  }) => Promise<void>
  isLoading: boolean
  isError: boolean
}
type PostData = {
  data: undefined | null
  isLoading: boolean
  isError?: boolean
  post: (body: any, onSuccess: () => void) => Promise<void>
}
interface ContextInterface {
  readonly projectsData?: ReducerState<APIProject[]>
  readonly filters: {
    data: Record<string, Filter>
    setAll: React.Dispatch<React.SetStateAction<Record<number, Filter>>>
    setSingle: (id: number, value: boolean) => void
  }
  contactData: ReturnType<typeof useContactData>
  contactForm: PostData
  associates: ReducerState<Associate[]>
  partners: ReducerState<Partner[]>
  teamMembers: ReducerState<TeamMember[]>
  references: ReducerState<Reference[]>
  setHandleImgSubmit: React.Dispatch<React.SetStateAction<() => void>>
  handleImgSubmit: () => void
}

/**
 * Serves its value to all it's children. see react context documentation
 */
export const DataContext = createContext<ContextInterface>({
  filters: {
    data: {},
    setAll: () => {},
    setSingle: (id: number) => {},
  },
  contactData: {
    email: { errors: [], set: () => {}, value: '' },
    name: { errors: [], set: () => {}, value: '' },
    phone: { errors: [], set: () => {}, value: '' },
    message: { errors: [], set: () => {}, value: '' },
    image: { set: () => {}, value: '' },
    isValid: false,
    setErrors: () => {},
  },
  contactForm: {
    data: undefined,
    post: async (body = null, onSuccess = () => {}) => {},
    isLoading: false,
    isError: false,
  },
  associates: associatesInitialState,
  partners: partnersInitialState,
  teamMembers: teamMembersInitialState,
  references: referencesInitialState,
  setHandleImgSubmit: () => {},
  handleImgSubmit: () => {},
})

/**
 * fetches data and serves it through context
 */
const DataContainer: React.FC = ({ children }) => {
  const [filters, setFilters, setFilter] = useFilters()

  const [projects, getProjects] = useFilteredProjects()
  // get filtered projects
  useEffect(() => {
    const selectedFilters = _.values(filters)
      .filter(filter => filter.isSelected)
      .map(filter => filter.name) // these are filter values sent in the query - do NOT translate
    getProjects(selectedFilters)
  }, [filters])

  const contactData = useContactData()
  const contactForm = useContactForm()

  const [associates] = useAssociates()
  const [partners] = usePartners()
  const [teamMembers] = useTeamMembers()
  const [references] = useReferences()

  const [handleImgSubmit, setHandleImgSubmit] = useState<() => void>(
    () => () => {}
  )
  return (
    <DataContext.Provider
      value={{
        projectsData: projects,
        filters: { data: filters, setAll: setFilters, setSingle: setFilter },
        contactData,
        contactForm,
        associates,
        partners,
        teamMembers,
        references,
        setHandleImgSubmit,
        handleImgSubmit,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export default DataContainer
