import React from 'react'

const FaCircle = () => {
  return (
    <svg
      aria-hidden="true"
      width={6}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="rgba(0,0,0,.6)"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
      ></path>
    </svg>
  )
}

export default FaCircle
