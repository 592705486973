import { defineMessages } from 'react-intl'

export default defineMessages({
  required: {
    id: 'contact.form.errors.required',
    defaultMessage: 'Ovo polje je obavezno!',
  },
  nameAndSurname: {
    id: 'contact.form.errors.nameSurname.both',
    defaultMessage: 'Molimo unesite i ime i prezime!',
  },
  email: {
    id: 'contact.form.errors.email.valid',
    defaultMessage: 'Molimo unesite ispravnu email adresu!',
  },
})
