import { useReducer, useEffect } from 'react'
import api from 'api/paths'
import reducer, { ReducerState, ReducerAction } from 'api/load-reducer'
// import { TeamMember } from 'components/team/components/team-member/teamMember'
export interface TeamMember {
  name: string
  surname: string
  email: string
  isVip?: boolean
  phoneNumber?: string
  image?: string
}
export const initialState: ReducerState<TeamMember[]> = {
  isLoading: false,
  data: [],
}
type TeamMemberReducer = (
  state: ReducerState<TeamMember[]>,
  action: ReducerAction<TeamMember[]>
) => ReducerState<TeamMember[]>

const useTeamMembers = () => {
  const [state, dispatch] = useReducer(
    reducer as TeamMemberReducer,
    initialState
  )

  const getData = async () => {
    dispatch({ type: 'LOAD' })
    const response = await fetch(`${api.baseUrl}${api.paths.teamMembers}`)
    try {
      if (!response.ok) throw new Error(response.status.toString())
      const json = await response.json()
      dispatch({ type: 'SUCCESS', payload: json })
    } catch (err) {
      dispatch({ type: 'ERROR' })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return [state, getData] as const
}

export default useTeamMembers
