/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import ReactLightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

interface Props {
  images: {
    src: string
    caption?: string
  }[]
  index: number
  className: string
}
const Lightbox: React.FC<Props> = ({ images, index, children, className }) => {
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(index)
  return (
    <>
      <div className={className} onClick={() => setisOpen(true)}>
        {children}
      </div>

      {isOpen && (
        <ReactLightbox
          imageCaption={images[photoIndex].caption}
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(prev => (prev + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex(prev => (prev + 1) % images.length)
          }
        />
      )}
    </>
  )
}

export default Lightbox
