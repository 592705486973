import React from 'react';
import NavigationItems from '../navigation/NavigationItems'

function SiteNavigation(props) {

    return(
        <div className={props.wrapperClasses}>
            <div className="row h-100 align-items-center mx-auto">
                <NavigationItems />
            </div>
        </div>
    )
}

export default SiteNavigation