import fundImages from 'assets/images/funds'

export interface Fund {
  img: string
  link?: string
}

const funds: Fund[] = [
  {
    img: fundImages.eu,
    link: 'http://www.europski-fondovi.eu/',
  },
  {
    img: fundImages.kik,
    link: 'https://opzo-opkk.hr/',
  },
  {
    img: fundImages.rh,
    link: 'https://razvoj.gov.hr/',
  },
  {
    img: fundImages.sin,
    link: 'https://strukturnifondovi.hr/',
  },
]

export default funds
