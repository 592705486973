import eu from './eu.svg'
import kik from './kik-blue.svg'
import rh from './rh.svg'
import sin from './sin-blue.svg'

export default {
  eu,
  kik,
  rh,
  sin,
}
