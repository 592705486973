import React from 'react'

const FaPaperclip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={16}
      viewBox="0 0 448 512"
    >
      <path
        fill="#1B75BC"
        d="M380.7 67.31C351.6 38.22 304.4 38.22 275.3 67.31L83.31 259.3C36.54 306.1 36.54 381.9 83.31 428.7C130.1 475.5 205.9 475.5 252.7 428.7L404.7 276.7C410.9 270.4 421.1 270.4 427.3 276.7C433.6 282.9 433.6 293.1 427.3 299.3L275.3 451.3C216 510.6 119.1 510.6 60.69 451.3C1.417 392 1.418 295.1 60.69 236.7L252.7 44.69C294.3 3.092 361.7 3.092 403.3 44.69C444.9 86.28 444.9 153.7 403.3 195.3L219.5 379.1C191.3 407.3 144.9 404.7 119.1 373.6C98.67 346.1 100.8 308.6 124.9 284.5L276.7 132.7C282.9 126.4 293.1 126.4 299.3 132.7C305.6 138.9 305.6 149.1 299.3 155.3L147.5 307.1C134.1 319.7 133.8 339.7 144.1 353.6C157.1 369.8 182.2 371.2 196.9 356.5L380.7 172.7C409.8 143.6 409.8 96.41 380.7 67.31L380.7 67.31z"
      />
    </svg>
  )
}

export default FaPaperclip
